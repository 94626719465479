import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaFacebook, FaInstagram, FaPinterest, FaRegEnvelope } from "react-icons/fa";


const Footer = () => {
	return (
		<div className='container-footer'>
			<div className='footer-dividers'>
				<div className='contain-logo-footer'>
					<img src={require(`../../images/sjs2.png`)} alt='logo' />
				</div>
				<div className='footer-contact'>
					<h4 className='contact-title-footer'>Our Contacts</h4>
					<p className='contact-text'><FaRegEnvelope />contact@sistersjs.com</p>
					<p className='contact-text'><FaPhoneAlt />+1 (410) 514-4814</p>
				</div>
				<div className='container-footer-media'>
					<h4 className='contact-title-footer'>Follow Us</h4>
					<div className='contain-footer-media'>
						<Link to='https://www.facebook.com/thesistersjsCLEAN' style={{textDecoration:'none'}} className='social-btn-icon facebook'><FaFacebook /></Link>
						<Link to='https://www.instagram.com/thesistersjs' style={{textDecoration:'none'}} className='social-btn-icon instagram'><FaInstagram /></Link>
					</div>
				</div>
				<div className='container-privacy'>
					<h4 className='contact-title-footer'>Customer Links</h4>
					<Link to='/cookies-policies' style={{textDecoration:'none'}} className='privacy-links'>Cookies Policy</Link>
					<Link to='/cancellation-policies' style={{textDecoration:'none'}} className='privacy-links'>Cancellation Policy</Link>
					<Link to='/privacy-policies' style={{textDecoration:'none'}} className='privacy-links'>Privacy Policy</Link>
					<Link to='/faqs' style={{textDecoration:'none'}} className='privacy-links'>FAQ's</Link>
				</div>
			</div>
			<div className='footer-copy-media'>
				<div className='footer-copy'>
					<span className='container-rights'>SJS {new Date().getFullYear()} &copy; All Rights Reserved.</span>
				</div>
				<div className='footer-copy'>
					<p className='container-rights'>Elaborated by <Link to='https://k-ching.com' className='link-to-kching' style={{textDecoration: 'none'}}>K-Ching</Link></p>
				</div>
				<div className='footer-copy'>
					<p className='container-rights'>Images by Freepik</p>
				</div>
			</div>
		</div>
	);
};

export default Footer;