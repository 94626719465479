//Reacts
import { useEffect } from 'react';
//Components
import Layout from '../../hocs/Layout';
//Styles
import '../../styles/static-pages.css';
//Icons
import { FaCircle } from 'react-icons/fa';


const CancellationPolicies = () => {

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Cancellation Policy</h2>
					<p>At Sister's Janitorial Services, we understand that schedules can change. To ensure flexibility for both our clients and team, we 
						offer the following cancellation policy:
					</p>
					<p><FaCircle className='icon-policies' /><b>24-Hour Notice:</b> Cancellations made at least 24 hours before the scheduled service time will not incur any charges.
					</p>
					<p><FaCircle className='icon-policies' /><b>Same-Day Cancellations:</b> If you cancel on the same day as the scheduled service, a cancellation fee of 50% of the service cost will apply.
					</p>
					<p><FaCircle className='icon-policies' /><b>No-Show Fee:</b> If our team arrives at the scheduled time and location and is unable to access the property, a 50% no-show fee will apply.
					</p>
					<p>Please contact us at +1 (410) 514-4814 or contact@sistersjs.com as soon as possible if you need to reschedule or cancel.</p>
				</div>
			</div>
		</Layout>
	)
};

export default CancellationPolicies;