import React from 'react';
import { useState } from 'react';
import '../../styles/components.css';

export const FAQ = () => {
	const [answersVisible, setAnswersVisible]=useState({});

	const toggleAnswer = (index) => {
		setAnswersVisible({
			...answersVisible,
			[index]: !answersVisible[index]
		});
	};

	return (
		<div className='container-faq'>
			{faqData.map((item, index) => (
				<div className='faq-item'>
					<button className='faq-button' onClick={() => toggleAnswer(index)}>{item.question}</button>
					{answersVisible[index] && <p className='faq-text'>{item.answer}</p>}
				</div>
			))}
		</div>
	);
};

const faqData = [
	{
    	question: "How do I book a commercial cleaning service?",
    	answer: "To book our commercial cleaning service, simply contact us. We’ll then visit the specified location, send you a quote, and once you confirm, we’ll schedule the cleaning at your convenience."
  	},
	{
    	question: "What types of residential cleaning services do you offer?",
    	answer: "We offer Standard Cleaning for $150 (3 hours) and Deep Cleaning for $180 (3 hours). Both services are tailored to fit your specific needs."
  	},
  	{
    	question: "What is included in a Standard Residential Cleaning?",
    	answer: "Standard cleaning includes dusting, vacuuming, mopping, and basic cleaning of bathrooms and kitchens. If you have specific areas of focus, let us know!"
  	},
  	{
    	question: "What additional tasks are covered in a Deep Cleaning?",
    	answer: "Deep cleaning covers a more thorough approach, including baseboards, inside cabinets, and hard-to-reach areas that aren’t typically included in standard cleanings."
  	},
  	{
    	question: "Can I customize my cleaning plan?",
    	answer: "Yes! Our services are flexible. Let us know your preferences, and we’ll customize a plan that fits your needs and budget."
  	},
  	{
    	question: "How often can I schedule cleaning services?",
    	answer: "For residential cleanings, we offer one-time, monthly, bi-weekly, or weekly subscriptions. You can choose the frequency that best suits your lifestyle."
  	},
  	{
    	question: "What areas do you serve?",
    	answer: "We provide commercial cleaning services in MD (Maryland) and surrounding areas. Contact us to confirm availability in your specific location."
  	},
  	{
    	question: "Do you offer non-contract cleaning services?",
    	answer: "Yes, our cleaning subscriptions are non-contract, so you can book services as needed without a long-term commitment."
  	},
  	{
    	question: "How do I know your team is reliable?",
    	answer: "Our experienced team is available 24/7 and dedicated to high standards of quality and safety. We have years of experience and a reputation for trustworthy, dependable service."
  	},
  	{
    	question: "What if I only need a one-time clean?",
    	answer: "No problem! We offer a flexible one-time cleaning service that you can schedule whenever you need it, whether for a special occasion or just a refresh."
  	}
];