//React
import { useEffect } from 'react';
//Components
import Layout from '../../hocs/Layout';
import { FAQ } from '../../components/aditional/FAQ';
//Styles
import '../../styles/static-pages.css';


const FAQs = () => {

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container-faqs' id='faqs'>
				<h3 className='uppertitle-home'>Frecuents Asked Questions</h3>
				<FAQ />
			</div>
		</Layout>
	)
};

export default FAQs;