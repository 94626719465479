//React
import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../../styles/components.css';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
//Icons
import { FaArrowRightLong } from 'react-icons/fa6'


const quote = {
    initial: { opacity: 0, y: 50 },
    beforeAnimate: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0, transition: { delay: 0.5, duration: 1 } },
    exit: { opacity: 0, y: 50, transition: { duration: 0.8 } },
};

export const SwiperBanner = () => {

    const [activeSlide, setActiveSlide] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveSlide(swiper.activeIndex);  // Actualiza el estado con el índice actual del slide
    };

    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
                onSlideChange={handleSlideChange}
            >
                <SwiperSlide className='comercial'>
                    {activeSlide === 0 && (
                        <motion.div className='swiper-banner-home' variants={quote}
                            initial="initial"
                            beforeAnimate= "beforeAnimate"
                            animate="animate"
                            exit="exit">
                            <h3>Commercial Cleaning</h3>
                            <p>Thorough cleaning of offices and commercial spaces, ensuring a professional and healthy environment.</p>
                            <Link to='/services' className='button-home' style={{textDecoration: 'none'}}>
                                <div className='button-home-icon'>
                                    <FaArrowRightLong />
                                </div>
                                <span>See More</span>
                            </Link>
                        </motion.div>
                    )}
                </SwiperSlide>
                <SwiperSlide className='property'>
                    {activeSlide === 1 && (
                    <motion.div className='swiper-banner-home necklace' variants={quote}
                            initial="initial"
                            beforeAnimate="beforeAnimate"
                            animate="animate"
                            exit="exit">
                        <h3>Property Maintenance</h3>
                        <p>Maintenance services to keep the building in optimal operational and safety conditions.</p>
                            <Link to='/services' className='button-home' style={{textDecoration: 'none'}}>
                                <div className='button-home-icon'>
                                    <FaArrowRightLong />
                                </div>
                                <span>See More</span>
                            </Link>
                    </motion.div>
                    )}
                </SwiperSlide>
                <SwiperSlide className='surface'>
                    {activeSlide === 2 && (
                    <motion.div className='swiper-banner-home bracelet' variants={quote}
                            initial="initial"
                            beforeAnimate="beforeAnimate"
                            animate="animate"
                            exit="exit">
                            <h3>Disinfection Cleaning</h3>
                            <p>Deep cleaning with disinfectants to eliminate germs and viruses from high-touch surfaces.</p>
                            <Link to='/services' className='button-home' style={{textDecoration: 'none'}}>
                                <div className='button-home-icon'>
                                    <FaArrowRightLong />
                                </div>
                                <span>See More</span>
                            </Link>
                    </motion.div>
                    )}
                </SwiperSlide>
            </Swiper>
        </>
    );
}