//Components
import Layout from '../../hocs/Layout';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
//Styles
import '../../styles/services.css';
//Icons
import { FaArrowRightLong } from "react-icons/fa6";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import { LuExpand } from "react-icons/lu";
import { FaRegEye } from "react-icons/fa6";
import { LiaMedalSolid } from "react-icons/lia";


const ResidentialCleaning = () => {

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

    const { hash } = useLocation();

  	useEffect(() => {
    	if (hash) {
      		const element = document.querySelector(hash);
      		if (element) {
        		element.scrollIntoView({ behavior: 'smooth' });
      		}
    	}
  	}, [hash]);

	return (

		<Layout>
			<div className='container-comercial-banner'>
				<div className='contain-residential-banner'></div>
				<div className='container-comercial-service'>
					<h2>Residential cleaning</h2>
					<p>Residential cleaning services generally cover a broad range of cleaning tasks to maintain and enhance the cleanliness, comfort, and appearance of homes, apartments, and other residential spaces. Here’s a list of commonly included services</p>
				</div>
			</div>
			<div className='container-standard-service' id='standard'>
				<h2 className='standard-service-title'>Standard Cleaning</h2>
				<div className='contain-deep-service'>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Bathroom</h3>
						<p>Toilet cleaned and disinfected top and behind</p>
						<p>Sink and mirror cleaned and disinfected</p>
						<p>Vacuuming and mopped</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Bedrooms</h3>
						<p>Change linens by request</p>
						<p>Clean furniture such as night stands and dressers</p>
						<p>Remove cobwebs</p>
						<p>Vents and fan</p>
						<p>Mirror and any glass decoration</p>
						<p>Vacuuming and mopped</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Kitchen and Living Room</h3>
						<p>Stove</p>
						<p>Microwave</p>
						<p>Toaster</p>
						<p>RefrigeratorDoor</p>
						<p>Ovendoor</p>
						<p>Counters</p>
						<p>Remove trash</p>
						<p>Vacuuming and mopped</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Living Room</h3>
						<p>Clean all tables</p>
						<p>Vacuum couches and floors</p>
						<p>Light switches</p>
						<p>Vacuuming and mopping</p>
					</div>
					<div className='contain-deep-info'>
						<p>Standard cleaning is a light cleaning</p>
						<p>We are open to hear any special requests to make it a more customized service</p>
					</div>
					<div>
						<Link className='packs-button' to='/contact' style={{textDecoration: 'none'}}>
	      					<span>Book In</span>
	      				</Link>
	      			</div>
				</div>
			</div>
			<div className='container-deep-service' id='deep'>
				<h2 className='deep-service-title'>Deep Cleaning</h2>
				<div className='contain-deep-service'>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Bathroom</h3>
						<p>Cleaning vents, baseboards, tub or shower washed and disinfected</p>
						<p>Toilets top and bottom cleaned</p>
						<p>Sink and mirrors</p>
						<p>Fixtures lights</p>
						<p>Vacuum and mop floors</p>
						<p>Glass door scrubbed and let it shine</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Bedrooms</h3>
						<p>Change linens and wash them</p>
						<p>Change towels (if client requests)</p>
						<p>Move table nights and clean under,behind and top</p>
						<p>Vacuum and mop under beds</p>
						<p>Furniture polish</p>
						<p>Mirrors shine</p>
						<p>Baseboard</p>
						<p>Vents and fans dusted</p>
						<p>Cobwebs removed</p>
						<p>Finger prints on doors in each room and bathrooms</p>
						<p>Floor and carpet deodorized</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Kitchen and Living Room</h3>
						<p>All appliances (inside refrigerator and oven just by request and with extra change)</p>
						<p>Counters</p>
						<p>Kitchen cabinets doors</p>
						<p>Light fixtures</p>
						<p>Fans and vents</p>
						<p>Chair legs</p>
						<p>Furniture polish</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Laundry and Power Room</h3>
						<p>Organized, cleaned, vacuumedandmopped</p>
						<p>Besides cleaning, we also organize rooms, it will be helpful if you have a laundry basket in the room to put allthe dirty clothes.</p>
					</div>
					<div className='contain-deep-info'>
						<h3 className='deep-info-title'>Living Room</h3>
						<p>Removepillowsandvacuum couchtopandunderpillows</p>
						<p>Cleanwithasoftclothtvandallelectronics</p>
						<p>Polishfurniture</p>
						<p>Baseboards</p>
						<p>Ventsandfans</p>
						<p>Cleananyglasstableordecoration</p>
						<p>Removedustfrom picturesandframes</p>
						<p>Vacuumingandmopping</p>
					</div>
					<div className='contain-deep-info'>
						<p>(Scrubbing floors just by request)</p>
					</div>
					<div>
						<Link className='packs-button' to='/contact' style={{textDecoration: 'none'}}>
	      					<span>Book In</span>
	      				</Link>
	      			</div>
				</div>
			</div>
		</Layout>
	);
};

export default ResidentialCleaning;