//React
import { useRef, useState } from 'react';
//Components
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';
//Styles
import '../../styles/components.css';
//Icons
import { FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";


export const ContactForm = () => {

    const form = useRef();
    const [sendedEmail, setSendedEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_edru7el', 'template_wdov2qh', form.current, {
                publicKey: '22tKECBM3o4X0FoPS',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSendedEmail(true);
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className='container-contact-form'>
            <form className='contact-form' ref={form} onSubmit={sendEmail}>
                <div className='contain-contact-form'>
                    <h4 className='contact-title'>We'd love to hear from you</h4>
                    <div className='input-contact'>
                        <input
                            type='text'
                            name='user_name'
                            className='input-contact-form'
                            required
                        />
                        <span>Full Name</span>
                        <i></i>
                    </div>
                    <div className='input-contact'>
                        <input
                            type='email'
                            name='user_email'
                            className='input-contact-form'
                            required
                        />
                        <span>Email</span>
                        <i></i>
                    </div>
                    <div className='input-contact'>
                        <input 
                            type='text' 
                            name='subject' 
                            className='input-contact-form' 
                            required 
                        />
                        <span>Subject</span>
                        <i></i>
                    </div>
                    <div className='input-contact-msg'>
                        <textarea name='message' className='input-contact-form' required></textarea>
                        <span>You Message</span>
                        <i></i>
                    </div>
                    <p className='required-fields'>All fields are required</p>
                    <div className='container-submit-button'>
                        {sendedEmail ? (
                            <p className='submited-contact-button'>Sent</p>
                        ) : (
                            <button type='submit' className='submit-button'>
                                <span>Send</span>
                            </button>
                        )}
                    </div>
                </div>
            </form>
            <div className='container-info-contact'>
                <div className='contain-info-contact'>
                    <div className='info-contact'>
                        <FaPhoneAlt className='contact-icon' />
                        <p className='info-contact-text'>Phone Number</p>
                    </div>
                    <Link to='sms:14105144814&body=Thank you for contacting SJS how can we help you?' className='info-contact-link' style={{textDecoration: 'none'}}>+1 (410) 514-4814</Link>
                </div>
                <div className='contain-info-contact'>
                    <div className='info-contact'>
                        <FaRegEnvelope className='contact-icon' />
                        <p className='info-contact-text'>Email</p>
                    </div>
                    <Link to='mailto:contact@sistersjs.com' className='info-contact-link' style={{textDecoration: 'none'}}>contact@sistersjs.com</Link>
                </div>
            </div>
        </div>
    )
};