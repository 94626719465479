//React
import React from 'react';
//Components
import { Link } from 'react-router-dom';
//Styles
import '../../styles/components.css';
//Icons
import { FaRegMessage } from "react-icons/fa6";


export const WhatsAppButton = () => {
	return (
		<Link to='sms:14105144814&body=Hello SJS Team, I’m interested in learning more about your janitorial services. Could you please provide more information? Thank you!' className='whatsapp-button' style={{textDecoration: 'none'}}>
			<div className='whatsapp-icon'><FaRegMessage /></div>
			<p>Contact Us!</p>
		</Link>
	);
};