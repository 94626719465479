//Components
import { NavLink, Link } from 'react-router-dom';
import { Fragment, useState } from 'react';
//Styles
import '../../styles/navigation.css';
//Icons
import { FaFacebook, FaInstagram, FaPinterest } from "react-icons/fa";
import { FaXTwitter, FaPhone } from 'react-icons/fa6';
import { MdArrowRightAlt } from "react-icons/md";


function Navbar() {

	const [menuOpen, setMenuOpen] = useState(false);

	const toggleMenu = () => {
    	setMenuOpen(!menuOpen);
  	};

	return (
		<>	
			<div className='container-navbar'>
				<div className='contain-upper-navbar'>
					<div className='container-uppernav-info'>
						<p className='call-now'>Call Us Now:</p>
						<Link to='/' className='uppernav-info-link' style={{textDecoration: 'none'}}><FaPhone />410-514-4814</Link>
						<p>|</p>
						<Link to='/' className='uppernav-info-link' style={{textDecoration: 'none'}}><FaPhone />410-987-1788</Link>
					</div>
					<div className='container-uppernav-social'>
						<Link to='/contact' className='uppernav-info-link' style={{textDecoration: 'none'}}>Request Proposal<MdArrowRightAlt /></Link>
						<p>|</p>
						<div className='upper-nav-social'>
							<Link to='https://www.facebook.com/thesistersjsCLEAN' className='navbar-social-buttons facebook' target='_blank' style={{textDecoration:'none'}}>
								<FaFacebook className='social-nav-icon' />
							</Link>
							<Link to='https://www.instagram.com/thesistersjs' className='navbar-social-buttons instagram' target='_blank' style={{textDecoration:'none'}}>
								<FaInstagram className='social-nav-icon' />
							</Link>
						</div>
					</div>
				</div>
				<div className='contain-navbar'>
					<div className='container-logo'>
						<Link to='/' style={{textDecoration: 'none'}}><img src={require(`../../images/sjs.png`)} alt='sjs' /></Link>
					</div>
					<div className={`container-navlinks navbar ${menuOpen ? 'menu-active' : ''}`}>
						<NavLink to='/' className={`btn-navbar ${isActive => isActive ? 'active' : ''}`} style={{textDecoration: 'none'}}><span>Home</span></NavLink>
						<NavLink to='/about' className='btn-navbar' style={{textDecoration: 'none'}}><span>About</span></NavLink>
						<NavLink to='/services' className='btn-navbar' style={{textDecoration: 'none'}}><span>Services</span></NavLink>
						<NavLink to='/contact' className='btn-navbar' style={{textDecoration: 'none'}}><span>Contact</span></NavLink>
						<div className='contain-navbar-pc-off'>
							<Link to='/' className='uppernav-info-link' style={{textDecoration: 'none'}}><FaPhone />410-514-4814</Link>
							<Link to='/' className='uppernav-info-link' style={{textDecoration: 'none'}}><FaPhone />410-987-1788</Link>
							<div className='navbar-pc-off'>
								<Link to='https://www.facebook.com/thesistersjsCLEAN' className='navbar-social-buttons facebook' target='_blank' style={{textDecoration:'none'}}>
									<FaFacebook className='social-nav-icon' />
								</Link>
								<Link to='https://www.instagram.com/thesistersjs' className='navbar-social-buttons instagram' target='_blank' style={{textDecoration:'none'}}>
									<FaInstagram className='social-nav-icon' />
								</Link>
							</div>
						</div>
					</div>
					<button className='nav-open-btn' id='menu-toggle' aria-label='Toggle Menu' onClick={toggleMenu}>
						<span className={`line ${menuOpen ? 'line-1' : ''}`}></span>
						<span className={`line ${menuOpen ? 'line-2' : ''}`}></span>
						<span className={`line ${menuOpen ? 'line-3' : ''}`}></span>
					</button>
				</div>
			</div>
		</>
	);
};

export default Navbar;