//Components
import Layout from '../../hocs/Layout';
import { useEffect } from 'react';
import { WrapperForm } from '../../components/forms/WrapperForm';
//Styles
import '../../styles/services.css';


const Services = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (

		<Layout>
			<div className='container-comercial-banner'>
				<div className='contain-comercial-banner'></div>
				<div className='container-comercial-service'>
					<h2 className='uppertitle-home'>Commercial cleaning</h2>
					<p>Commercial cleaning services generally cover a broad range of cleaning tasks to
						maintain and improve the cleanliness and appearance of office buildings, retail stores,
						warehouses, and other commercial spaces. Here’s a list of commonly included services</p>
				</div>
			</div>
			<div className='container-comercial-items'>
				<div className='contain-comercial-items'>
					<div className='comercial-item'>
						<h3 className='comercial-item-title'>Routine Cleaning Services</h3>
						<p>Dusting and wiping surfaces</p>
						<p>Vacuuming carpets and rugs</p>
						<p>Sweeping and mopping hard floors</p>
						<p>Trash collection and removal</p>
						<p>Restocking restroom supplies</p>
						<p>Cleaning and sanitizing bathrooms</p>
						<p>Cleaning kitchens and break areas</p>
						<p>Window and glass cleaning (interior)</p>
						<p>Spot cleaning walls and doors</p>
					</div>
					<div className='comercial-item'>
						<h3 className='comercial-item-title'>Deep Cleaning Services</h3>
						<p>Detailed dusting of high surfaces</p>
						<p>Cleaning vents, fans, and light fixtures</p>
						<p>Carpet deep cleaning and shampooing</p>
						<p>Upholstery cleaning</p>
						<p>Polishing floors or stripping and waxing floors</p>
						<p>Grout cleaning for tile areas</p>
					</div>
					<div className='comercial-item'>
						<h3 className='comercial-item-title'>Specialty Cleaning Services</h3>
						<p>Post-construction cleaning</p>
						<p>Event cleaning (before and after)</p>
						<p>Medical or healthcare facility cleaning (disinfection protocols)</p>
						<p>Window cleaning (exterior for multi-story buildings)</p>
						<p>Green or eco-friendly cleaning services</p>
					</div>
					<div className='comercial-item'>
						<h3 className='comercial-item-title'>Seasonal or Periodic Services</h3>
						<p>Floor polishing or refinishing</p>
					</div>
					<div className='comercial-item'>
						<h3 className='comercial-item-title'>Sanitization and Disinfection</h3>
						<p>Routine disinfection of high-touch areas</p>
						<p>Specialized sanitization for areas like kitchens or bathrooms</p>
					</div>
					<div className='comercial-item'>
						<h3 className='comercial-item-title'>Office and Workspace Organization (upon request)</h3>
						<p>Desk and workspace organization</p>
						<p>Filing and tidying up common areas</p>
					</div>
				</div>
				<div>
					<WrapperForm />
				</div>
			</div>
		</Layout>
	);
};

export default Services;