import Layout from '../../hocs/Layout';
import { FaCircle } from 'react-icons/fa';
import '../../styles/static-pages.css';
import { useEffect } from 'react';


const CookiesPolicies = () => {

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Cookies Policy</h2>
					<p>
						This Cookies Policy explains how Sister's Janitorial Services uses cookies and similar technologies to recognize you when 
						you visit our website. It explains what these technologies are, why we use them, and your rights to control our use of them.
					</p>
					<h3>What Are Cookies?</h3>
					<p>
						Cookies are small text files placed on your device to store data that can be retrieved by a web server in the domain that 
						placed the cookie. We use cookies and similar identifiers for various purposes, such as remembering your preferences, 
						tracking site visits, and enhancing your overall user experience.
					</p>
					<h3>Types of Cookies We Use:</h3>
					<div>
						<p className='subtitle-policies'><FaCircle className='icon-policies' />Essential Cookies:
							<span> These cookies are essential to provide you with services available through our website and allow you to use some 
							of its features. For example, they enable us to remember the products you’ve added to the shopping cart.
							</span>
						</p>
						<p className='subtitle-policies'><FaCircle className='icon-policies' />Performance and Analytics Cookies: 
							<span> These cookies allow us to recognize and count the number of visitors and see how they navigate the website. This 
							helps us improve the functionality of our website, ensuring that users can easily find what they are looking for.
							</span>
						</p>
						<p className='subtitle-policies'><FaCircle className='icon-policies' />Functionality Cookies: 
							<span> These cookies are used to recognize you when you return to our website. They allow us to personalize our content 
							for you, greet you by name, and remember your preferences (such as your language or region).
							</span>
						</p>
					</div>
					<h3>Controlling Cookies</h3>
					<p>
						You can control and manage cookies in various ways. Please note that deleting or blocking cookies may affect your user 
						experience, and some parts of our website may become inaccessible.
					</p>

					<h3>Changes to Our Cookie Policy</h3>
					<p>
						We reserve the right to modify this Cookie Policy at any time and for any reason. We encourage you to periodically review 
						this page to stay informed about our use of cookies.
					</p>
					<h3>Contact Us</h3>
					<p>If you have questions about our Cookie Policy, please contact us at contact@sistersjs.com or call +1 (410) 514-4814.</p>
					<p>This Cookie Policy was last updated on October 28, 2024.</p>
				</div>
			</div>
		</Layout>
	)
};

export default CookiesPolicies;