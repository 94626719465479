import React from 'react';
import { Link } from 'react-router-dom';
//Styles
import '../../styles/components.css';
//Icons
import { FaArrowRightLong } from "react-icons/fa6";


export const ParallaxSection = ({ title, text, className, link, button }) => {
    return (
        <div className="parallax-section">
            <div className={`parallax-background ${className}`}></div>
            <div className="parallax-content">
                <h3>{title}</h3>
                <Link className='button-home large-button' to={link} style={{textDecoration: 'none'}}>
                    <div className='button-home-icon'>
                        <FaArrowRightLong />
                    </div>
                    <span>{button}</span>
                </Link>
            </div>
        </div>
    );
};