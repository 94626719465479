//React
import { useRef, useState } from 'react';
//Components
import emailjs from '@emailjs/browser';
//Styles
import '../../styles/components.css';


export const WrapperForm = () => {

    const form = useRef();
    const [sendedEmail, setSendedEmail] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_edru7el', 'template_wdov2qh', form.current, {
                publicKey: '22tKECBM3o4X0FoPS',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSendedEmail(true);
                    form.current.reset();
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <div className='container-wrapper-form'>
            <form className='wrapper-form' ref={form} onSubmit={sendEmail}>
                <div className='input-contact'>
                    <input
                        type='text'
                        name='user_name'
                        className='input-contact-form'
                        required
                    />
                    <span>Full Name</span>
                    <i></i>
                </div>
                <div className='input-contact'>
                    <input
                        type='email'
                         name='user_email'
                        className='input-contact-form'
                        required
                    />
                    <span>Email</span>
                    <i></i>
                </div>
                <div className='input-contact'>
                    <input 
                        type='text' 
                        name='subject' 
                        className='input-contact-form' 
                        required 
                    />
                    <span>Subject</span>
                    <i></i>
                </div>
                <div className='input-contact-msg'>
                    <textarea name='message' className='input-contact-form' required></textarea>
                    <span>You Message</span>
                    <i></i>
                </div>
                <p className='required-fields'>All fields are required</p>
                <div className='container-submit-button'>
                    {sendedEmail ? (
                        <p className='submited-contact-button'>Sent</p>
                    ) : (
                        <button type='submit' className='submit-button'>
                            <span>Send</span>
                        </button>
                    )}
                </div>
            </form>
        </div>
    )
};