//Components
import Layout from '../../hocs/Layout';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
//Styles
import '../../styles/static-pages.css';
//Icons
import { FaArrowRightLong } from 'react-icons/fa6';


const About = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);

	return (
		<Layout>
			<div className='container-presentation-about'>
				<div className='contain-presentation-about'></div>
				<div className='presentation-about'>
					<h2 className='uppertitle-home'>Built on Trust and Quality Service</h2>
					<p className='aboutus-text'>Founded in 2016 by Irma Arias, The SJS brings over 15 years of expertise in the cleaning industry. Starting with a passion for creating spotless, welcoming homes, Irma quickly became known for her dedication, attention to detail, and commitment to excellence. Today, she leads The SJS into the commercial cleaning sector, delivering that same level of care and professionalism to businesses across the area.</p>
				</div>
			</div>
			<div className='container-mision-vision'>
				<div className='mision-vision-value'>
					<div className='mision-vision-img'>
						<img src={require(`../../images/mision.webp`)} alt='mision' />
					</div>	
					<h3>Our Mission</h3>
					<div className='mision-vision-text'>
						<p>To enhance and maintain the cleanliness of commercial spaces, promoting healthier environments and elevating our clients' business image.</p>
					</div>
				</div>
				<div className='mision-vision-value vision-center'>
					<div className='mision-vision-img'>
						<img src={require(`../../images/vision.webp`)} alt='vision' />
					</div>	
					<h3>Our Vision</h3>
					<div className='mision-vision-text'>
						<p>To be the trusted commercial cleaning partner for businesses across the region, known for quality, flexibility, and an unwavering commitment to client satisfaction.</p>
					</div>
				</div>
				<div className='mision-vision-value'>
					<div className='mision-vision-img'>
						<img src={require(`../../images/values.webp`)} alt='valores' />
					</div>	
					<h3>Our Values</h3>
					<div className='mision-vision-text'>
						<p>Our work is guided by values of integrity, reliability, and a client-first approach. We believe in transparent communication, outstanding service, and creating lasting partnerships with our clients.</p>
					</div>
				</div>
			</div>
			<div className='container-about-information'>
				<div className='about-information-content'>
					<div className='about-information-img'>
						<img src={require(`../../images/img-about.png`)} alt='' />
					</div>
					<div className='contain-about-software'>
						<p>At The SJS, we understand that a clean environment is essential for productivity, health, and first impressions. That’s why 
						our team is dedicated to meeting the unique needs of commercial spaces, from offices to large facilities. Our approach is 
						flexible, allowing us to design custom cleaning plans that fit seamlessly into your schedule and requirements. Whether it’s 
						a regular maintenance clean or a deep, comprehensive service, we go above and beyond to create spaces that reflect your 
						standards.</p>
						<Link to='/services' className='button-home' style={{textDecoration: 'none'}}>
							<div className='button-home-icon'>
								<FaArrowRightLong />
							</div>
							<span>See More</span>
						</Link>
					</div>
				</div>
			</div>
			<WhatsAppButton />
		</Layout>
	);
};

export default About;