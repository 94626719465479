//React
import { useEffect } from 'react';
//Components
import Layout from '../../hocs/Layout';
//Styles
import '../../styles/static-pages.css';
//Icons
import { FaCircle } from 'react-icons/fa';


const PrivacyPolicies = () => {

	useEffect(() => {
      	window.scrollTo(0,0)
    }, [])

	return (
		<Layout>
			<div className='container'>
				<div className='container-policies'>
					<h2>Privacy Policy</h2>
					<p>
						At Sister's Janitorial Services, we are committed to protecting our users' privacy. This privacy policy describes how we 
						collect, use, and protect the personal information you provide when using our website. By using our website, you agree to 
						the terms and conditions of this privacy policy.
					</p>
					<h3>Information We Collect:</h3>
					<p>
						We collect personal information that you voluntarily provide to us, such as your name, email address, phone number, mailing 
						address, and payment information when you create an account, make a purchase, participate in surveys, or communicate with us.
					</p>
					<h3>How We Use the Information:</h3>
					<p><FaCircle className='icon-policies' />We use the personal information we collect to provide the products and services you 
						request, process your transactions, send communications related to your account, respond to your inquiries, and offer customer 
						support.
						</p>
					<p><FaCircle className='icon-policies' />We may also use your personal information to improve our website, personalize your user 
						experience, manage contests, promotions, and surveys, and send you information about products, services, and promotions that may 
						interest you.
					</p>
					<p><FaCircle className='icon-policies' />We do not sell, rent, or share your personal information with unaffiliated third parties 
						for marketing purposes without your explicit consent.
					</p>
					<h3>Information Security:</h3>
					<p>
						We implement administrative, technical, and physical security measures to protect the personal information we collect and keep 
						it secure from loss, unauthorized access, disclosure, alteration, or destruction. However, please note that no security 
						measure is infallible, and no data transmission over the Internet can be guaranteed to be completely secure.
					</p>
					<h3>Cookies and Similar Technologies:</h3>
					<p>
						We use cookies and similar technologies to automatically collect certain information when you visit our website. These 
						technologies help us improve your user experience, provide website functionality, and analyze website traffic. You may choose 
						to decline cookies through your browser settings; however, please note that this may affect our website’s functionality.
					</p>
					<h3>Third-Party Links:</h3>
					<p>
						Our website may contain links to third-party websites that are not under our control. We are not responsible for the privacy 
						practices or content of these third-party sites. We encourage you to review the privacy policies of these websites before 
						providing any personal information.
					</p>
					<h3>Consent and Changes to the Privacy Policy:</h3>
					<p>
						By using our website, you agree to the terms of this privacy policy. We reserve the right to modify, amend, or update this 
						privacy policy at any time and without prior notice. Any changes to this privacy policy will be effective when posted on 
						this page.
					</p>
					<h3>Contact Us:</h3>
					<p>
						If you have any questions, concerns, or requests regarding our privacy policy, please feel free to contact us via the contact 
						methods provided on our website.
					</p>
					<p>
						This privacy policy is designed to inform you about how we collect, use, and protect your personal information at Sister's 
						Janitorial Services. By using our website, you accept the terms and conditions of this privacy policy.
					</p>
				</div>
			</div>
		</Layout>
	)
};

export default PrivacyPolicies;