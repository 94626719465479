import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Error404 from './containers/errors/Error404';
// Static Pages
import Home from './containers/pages/Home';
import About from './containers/pages/About';
import Contact from './containers/pages/Contact';
import Services from './containers/pages/Services';
import ResidentialCleaning from './containers/pages/ResidentialCleaning'
//Policies
import CancellationPolicies from './containers/policies/CancellationPolicies';
import CookiesPolicies from './containers/policies/CookiesPolicies';
import PrivacyPolicies from './containers/policies/PrivacyPolicies';
import FAQs from './containers/policies/FAQs';


function App() {
    return (
            <Router>
                <Routes>
                    {/* Error Display */}
                    <Route path='*' element={<Error404 />} />

                    {/* Static Pages */}
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/about' element={<About />} />
                    <Route exact path='/contact' element={<Contact />} />
                    <Route exact path='/services' element={<Services />} />
                    <Route exact path='/services/residential' element={<ResidentialCleaning />} />

                    {/* Policies */}
                    <Route exact path='/cancellation-policies' element={<CancellationPolicies />} />
                    <Route exact path='/cookies-policies' element={<CookiesPolicies />} />
                    <Route exact path='/privacy-policies' element={<PrivacyPolicies />} />
                    <Route exact path='/faqs' element={<FAQs />} />
                </Routes>
            </Router>
    );
}

export default App;