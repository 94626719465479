//React
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
//Components
import Layout from '../../hocs/Layout';
import { SwiperBanner } from '../../components/aditional/SwiperBanner';
import { WhatsAppButton } from '../../components/aditional/WhatsApp';
import { reviews } from '../../helpers/Reviews';
import Stars from '../../components/aditional/Stars';
import { ParallaxSection } from '../../components/aditional/ParallaxSection';
//Styles
import '../../styles/static-pages.css';
//Icons
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineCleaningServices } from "react-icons/md";
import { GiVacuumCleaner } from "react-icons/gi";
import { FaQuoteRight } from "react-icons/fa6";


const Home = () => {

	useEffect(() => {
		window.scrollTo(0,0)
	}, []);
	
	return (
		<Layout>
			<div className='container-banner-home'>
				<SwiperBanner />
			</div>
			<div className='container-home-steps'>
				<h2 className='uppertitle-home'>How It Works</h2>
				<p className='home-step-text'>Our process is simple and efficient. First, reach out to us to discuss your cleaning needs. We’ll visit the specified location to assess the work, then provide you with a customized quote. Once you confirm, we’ll coordinate a convenient time to complete your cleaning service, ensuring everything meets your expectations</p>
				<div className='container-items'>
					<div className='steps-items'>
						<div className='steps-item-img'>
							<img src={require(`../../images/step1.png`)} alt='step-1' />
						</div>
						<div className='steps-item-text'>
							<h4>Step 1</h4>
							<p>Contact us</p>
						</div>
					</div>
					<div className='steps-items'>
						<div className='steps-item-img'>
							<img src={require(`../../images/step2.png`)} alt='step-2' />
						</div>
						<div className='steps-item-text'>
							<h4>Step 2</h4>
							<p>We visit the specified location</p>
						</div>
					</div>
					<div className='steps-items'>
						<div className='steps-item-img'>
							<img src={require(`../../images/step3.png`)} alt='step-3' />
						</div>
						<div className='steps-item-text'>
							<h4>Step 3</h4>
							<p>We send you our quote for the work</p>
						</div>
					</div>
					<div className='steps-items'>
						<div className='steps-item-img'>
							<img src={require(`../../images/step4.png`)} alt='step-4' />
						</div>
						<div className='steps-item-text'>
							<h4>Step 4</h4>
							<p>You confirm, and we schedule the cleaning</p>
						</div>
					</div>
				</div>
				<Link to='/contact' className='button-home' style={{textDecoration: 'none'}}>
					<div className='button-home-icon'>
						<FaArrowRightLong />
					</div>
					<span>Book In</span>
				</Link>
			</div>
    		<div className='container-home-plans'>
				<h4 className='uppertitle-home'>Residential Cleaning</h4>
				<p className='plans-text'>Comprehensive cleaning services designed to keep homes fresh, organized, and hygienic. From kitchens and bathrooms to living areas and bedrooms, residential cleaning ensures every corner of your home is spotless and welcoming.</p>
	      		<div className='contain-home-plans'>
	      			<div className='container-pack'>
	      				<div className='contain-bubbles'></div>
						<h3 className='pack-title'>Standard Cleaning</h3>
						<div className='plans-icon'>
							<MdOutlineCleaningServices />
						</div>
						<div className='pack-price'>
							<p>USD</p>
							<p className='price-number'>$150.00</p>
						</div>
						<div className='pack-add-cart'>
							<Link className='packs-button' to='/services/residential#standard' style={{textDecoration: 'none'}}>
	      						<span>See more</span>
	      					</Link>
						</div>
						<div className='pack-paragraph'>
							<p className='check-list-text'>Cleaning cost for 3 hours</p>
						</div>
					</div>
					<div className='container-pack'>
						<div className='contain-bubbles bubbles-1'></div>
						<h3 className='pack-title'>Deep Cleaning</h3>
						<div className='plans-icon'>
							<GiVacuumCleaner />
						</div>
						<div className='pack-price'>
							<p>USD</p>
							<p className='price-number'>$180.00</p>
						</div>
						<div className='pack-add-cart'>
							<Link className='packs-button' to='/services/residential#deep' style={{textDecoration: 'none'}}>
	      						<span>See more</span>
	      					</Link>
						</div>
						<div className='pack-paragraph'>
							<p className='check-list-text'>Cleaning cost for 3 hours</p>
						</div>
					</div>
	      		</div>
    		</div>
			<div className='container-home-stadistics'>
				<div className='contain-home-stadistics'>
					<div className='stadistics-info'>
						<div className='contain-stadistic-info'>
							<h3 className='stadistics-number'>+4K</h3>
							<p className='stadistics-text'>Satisfied Clients</p>
						</div>
						<div className='contain-stadistic-info'>
							<h3 className='stadistics-number'>+10K</h3>
							<p className='stadistics-text'>Homes Cleaned</p>
						</div>
						<div className='contain-stadistic-info'>
							<h3 className='stadistics-number'>+12</h3>
							<p className='stadistics-text'>Companies That Trust Us</p>
						</div>
						<div className='contain-stadistic-info'>
							<h3 className='stadistics-number'>+20</h3>
							<p className='stadistics-text'>Years of Experience</p>
						</div>
					</div>
				</div>
			</div>
			<div>
    			<ParallaxSection title='Efficient cleaning services to maintain professional, hygienic environments in office buildings, retail spaces, and other commercial facilities' 
    				className='comercial-banner'
    				button='See More'
    				link='/services'
    			/>
    		</div>
			<div className='container-home-about'>
				<div className='contain-home-about'>
					<div className='container-box-about'>
						<h2 className='uppertitle-home'>What inspired us to expand our service</h2>
						<p>With over 15 years of experience in residential cleaning, Irma now focuses on commercial cleaning</p>
						<h4>Learn more in our About Us section</h4>
						<Link className='button-home' to='/about' style={{textDecoration: 'none'}}>
							<div className='button-home-icon'>
								<FaArrowRightLong />
							</div>
							<span>About Us</span>
						</Link>
					</div>
				</div>
				<div className='about-home-img'>
    				<img src={require(`../../images/home-about.webp`)} alt='home-about' />
    			</div>
    		</div>
    		<div>
    			<ParallaxSection title='Our team is ready 24/7 to support all your business’s facility maintenance requirements'
    				button='Contact Us'
    				link='/contact'
    			/>
    		</div>
    		<div className='container-home-reviews'>
    			<div className='contain-home-reviews'>
    				{reviews.map((review, index) => (
                        <div className='container-testimonial-boxes'>
                            <FaQuoteRight className='testimonial-icon' />
                            <p className='testimonial-text'>{review.text}</p>
                            <div className='container-name-rating'>
                                <p className='testimonial-name'>- {review.name}</p>
                                <Stars rating={review.rating}/>
                            </div>
                        </div>
                    
                	))}
                </div>
                <Link to='https://g.co/kgs/1QUSK2A' target='_blank' style={{textDecoration: 'none'}} className='contain-google-review'>
                	<img src={require(`../../images/google-review-logo.png`)} alt='google-reviews' />
                </Link>
    		</div>
			<WhatsAppButton />
		</Layout>
	);
};

export default Home