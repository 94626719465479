export const reviews = [
	{
		id: 0,
		name: 'Walter Graves',
		text: 'We had The Sisters deep clean our home yesterday and we are very impressed! They made our 15 year old stove look brand new!! I could smell the clean when getting out of the car in the garage, and then when I went inside I could see the sparkling floors. We highly recommend The Sisters to everyone.',
		rating: 5,
	},
	{
		id: 1,
		name: 'Alexia George',
		text: 'This cleaning service has been the best that I have utilized in Maryland. Since, they started cleaning our home my allergies have reduced greatly. In addition, our pet friendly home has been spotless and smells amazing. You will not be disappointed hiring this company!',
		rating: 5,
	},
	{
		id: 2,
		name: 'Leslie Chickanosky',
		text: 'Irma is great to work with, very accommodating and responsive. My house looks and smells great after she has been here. She provides a very thorough cleaning. I have been very happy with the service she provides and definitely recommend her.',
		rating: 5,
	},
]